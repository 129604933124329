import AppLoader from 'components/AppLoader'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

const LocationDetails = lazy(() => import('pages/LocationDetails'))

const PageAccount = lazy(
  () => import(/* webpackChunkName: "page-account" */ 'pages/PageAccount')
)
const PageNotFound = lazy(
  () => import(/* webpackChunkName: "page-not-found" */ 'pages/PageNotFound')
)

export interface PageAccountParams {
  accountUrl?: string
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        <Switch>
          <Route
            component={LocationDetails}
            path="/:accountUrl/locationDetails/:locationId"
          />
          <Route component={PageAccount} path="/:accountUrl" />
          <Route component={PageNotFound} path="*" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
