import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { API_HOST } from 'utils/constants'

const host = API_HOST ?? ''
const uri = `${host}/pws/ptd/graphql`

const link = createHttpLink({ uri })

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({ link, cache })
