import { css } from 'styled'

const globalStyle = css`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 100vh;
  }

  html {
    --content-max: 1024px;
    --gap: 2.5rem;
  }

  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default globalStyle
