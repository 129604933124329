import { ApolloProvider } from '@apollo/client'
import React, { useEffect } from 'react'
import { GlobalStyle, ThemeProvider, theme } from 'styled'
import ReactGA from 'react-ga4'

import Routes from 'Routes'
import { apolloClient } from 'utils/apolloClient'

import 'App.css'

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
      <GlobalStyle />
    </ApolloProvider>
  )
}

export default App
