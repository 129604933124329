export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
export const API_HOST = process.env.REACT_APP_AWS_ENDPOINT

export const __DEV__ = process.env.NODE_ENV === 'development'
export const NODE_ENV = process.env.NODE_ENV

export const CONFIGCAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY || ''

export const PROGRAM_DESCRIPTION =
  'We are committed to providing safe drinking water to all residents. Our dashboard provides information about lead service lines or drinking water contaminants. The results you see are a part of our lead and copper sampling program.'
