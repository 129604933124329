import styled, {
  css,
  createGlobalStyle,
  ThemeProvider
} from 'styled-components'
import { theme } from '@120wateraudit/envirio-components/dist/theme'
import globalStyle from 'styled/globalStyle'

const GlobalStyle = createGlobalStyle`${globalStyle}`

export { css, theme, GlobalStyle, ThemeProvider }

export default styled
