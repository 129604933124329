import React from 'react'
import styled from 'styled'

export interface AppLoaderProps {
  size?: string
}

const AppLoader = (props: AppLoaderProps) => {
  return (
    <Container {...props}>
      <LoaderCube />
      <LoaderCubeAccent />
    </Container>
  )
}

const Container = styled.div<AppLoaderProps>`
  position: relative;
  font-size: ${p => p.size ?? '3em'};
  width: 1em;
  height: 1em;
`

const LoaderCube = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: currentColor;
  width: 0.33em;
  height: 0.33em;
  font-size: inherit;
  animation: movecube 1.8s infinite ease-in-out;

  @keyframes movecube {
    25% {
      transform: translateX(0.66em) rotate(-90deg) scale(0.5);
    }
    50% {
      transform: translateX(0.66em) translateY(0.66em) rotate(-179deg);
    }
    50.1% {
      transform: translateX(0.66em) translateY(0.66em) rotate(-180deg);
    }
    75% {
      transform: translateX(0) translateY(0.66em) rotate(-270deg) scale(0.5);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

const LoaderCubeAccent = styled(LoaderCube)`
  animation-delay: -0.9s;
`

export default AppLoader
